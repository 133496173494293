import './App.css';
import 'bulma/css/bulma.min.css';
import landingLogo from './assets/rum-brothers-landing-image.jpg';

function App() {
  return (
    <div className="App main" style={{ justifyContent: 'center' }}>
      <section className="hero is-medium landing-hero">
        <div className="hero-body">
          <p className="title has-text-white-bis">Rum brothers</p>
          <p className="subtitle has-text-white-ter">
            Some prefer Rum, we prefer Gin
          </p>
        </div>
      </section>
    </div>
  );
}

export default App;
